.mob-infos-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  span {
    margin-bottom: 0.5em;
  }
  &__slot-wrapper {
    margin-bottom: 1em;
    padding: 1em;
    border: 1px solid grey;
    border-radius: 8px;
    &__groceries {
      text-align: center;
      &__item-wrapper {
        text-align: start;
        display: flex;
        flex-direction: column;
      }
      display: flex;
      flex-direction: column;
      &__bold {
        font-weight: 700;
      }
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    &__bold {
      font-weight: 700;
    }
  }
}
