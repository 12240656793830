.loader-wrapper {
  margin-top: 2em;
}

.trips-fc-container {
  text-align: center;
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  a {
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
  }
}

.trip-fc-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__header {
    padding-top: 1em;
    padding-bottom: 1em;
    width: 100%;
    text-align: center;
    background-color: #f4f4f4;
  }

  .print-area-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    &__box-infos {
      display: flex;
      margin-top: 1em;
      justify-content: center;
      align-items: center;

      &__counter-wrapper {
        margin-right: 0.5em;
        font-size: 1.5em;
        font-weight: 700;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        background-color: #f1f1f1;
        padding: 0.5em 1em;
        border-radius: 8px;
      }
    }

    &__navigation-buttons {
      display: flex;
      justify-content: space-between;
      width: 40%;
      margin-top: 5em;
      align-items: center;

      .fc-button {
        background-color: #eeeeee;
        border-radius: 51px;
        color: #0a478f;
        padding: 0.2em 2em;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      .disabled {
        background-color: #b9b9b9 !important;
        color: #4a4a4a !important;
        // padding: 0.5em 1em;
      }
    }
    span {
      font-weight: 700;
    }
    &__printed-tracker {
      margin-top: 2em;
      font-size: 2.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      &__bold {
        font-weight: 700;
        margin-right: 0.5em;
      }
      img {
        margin-left: 0.5em;
      }
    }
  }
}

.print {
  padding: 1em 2em !important;
}

.trip-fc {
  width: 70%;
  padding: 0.5em;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trip-fc span {
  flex-shrink: 0;
}

.trip-fc img {
  max-width: 20px;
  margin-left: 0.5em;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.trip-printed {
  background-color: #dddddd;
}

.day-navigation-header {
  margin-bottom: 1em;
}

.day-navigation {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  color: #0a478f;
  font-weight: 700;
  margin-bottom: 2em;
}

.navigation-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.first-day,
.last-day {
  background: #b9b9b9 !important;
  color: #4a4a4a !important;
  pointer-events: none;
  cursor: not-allowed;
}
.day-navigation div {
  background: #eeeeee;
  border: none;
  padding: 0.5em 2.5em;
  margin: 0 1em;
  cursor: pointer;
  border-radius: 1.5em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.day-navigation button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.day-navigation span {
  font-size: 20px;
  font-weight: bold;
}

.original-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toggle-trips-sort {
  background: #eeeeee;
  border: none;
  padding: 0.5em 2.5em;
  margin: 0 1em;
  cursor: pointer;
  border-radius: 1.5em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 1em;
}

.already-printed-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__buttons {
    margin-top: 2em;
    display: flex;
    width: 50%;
    justify-content: space-between;

    div {
      color: white;
      padding: 0.7em;
      border-radius: 6px;
      font-weight: 700;
      width: 20%;
      text-align: center;
    }

    &__cancel {
      background-color: #000000;
      opacity: 0.5;
    }
    &__reprint {
      background-color: #f04c4c;
    }
  }
}

.print-all {
  background-color: #eeeeee;
  border-radius: 51px;
  color: #0a478f;
  padding: 2em 2em;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 2em;
  font-size: 1em;
}

.picking-modal-container {
  &__buttons {
    margin-top: 2em;
    display: flex;
    width: 50%;
    justify-content: space-between;

    div {
      color: white;
      padding: 0.7em;
      border-radius: 6px;
      font-weight: 700;
      width: 20%;
      text-align: center;
    }

    &__cancel {
      background-color: #000000;
      opacity: 0.5;
    }
    &__confirm {
      background-color: #f04c4c;
    }
  }
}
