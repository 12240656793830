.v-cancel-prev-modal {
  &__header {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 300;
    text-align: center;
  }
  &__product {
    font-size: 18px;
    font-weight: 600;
    // text-align: start !important;
  }
  &__buttons {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    opacity: 0.9;
  }
  &__cancel-btn {
    border: 1px solid $error-main !important;
    background-color: $error-main;
  }
  &__ok-btn {
    border: 1px solid $grey !important;
    background-color: $grey;
  }
}
