.missing-product-toggler {
  position: absolute;
  top: 1em;
  right: 1em;
  padding: 0.5em;
  background-color: #ff6b6b;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  border-radius: 15px;

  img {
    max-width: 100%;
  }
}

.batch-details {
  margin-top: 50px;
}
.header-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 1em 80px;
  // margin-bottom: 1em;
}

.job-infos {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  span,
  input {
    margin: 0px 40px 0.5em;
  }
}

.button-container {
  // position: absolute;
  // bottom: 3em;
  width: 100%;
  margin-bottom: 20px;
  button {
    margin: auto;
    width: 78%;
  }
}

.restock-info-container {
  margin-top: 5em;
  text-align: center;
}

.list-products {
  border: solid 0.5px grey;
  border-radius: 8px;
  margin: 0px 10px 100px;
  padding: 0px 10px;
  width: fit-content;
  height: 80vh;
  overflow: scroll;
}

.missing-product-modal-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 20%;
  }
  span {
    font-size: 1.5em;
    text-align: center;
  }
  &__button-container {
    display: flex;
    margin-top: 1em;
    width: 90%;
    justify-content: space-between;
    .cancel {
      background-color: #000000;
      opacity: 0.5;
    }
    .confirm {
      background-color: #f04c4c;
    }
    div {
      padding: 0.5em;
      border-radius: 10px;
      min-width: 40%;
      color: white;
      font-weight: 700;
      text-align: center;
    }
  }
}
