.dropdown-button {
  width: 77px;
  padding: 10px;
  border: 1px solid #848484;
  cursor: pointer;
  background-color: #e3e3e3;
  border-radius: 13px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.dropdown-options {
  position: absolute;
  left: 3em;
  border: 1px solid #ccc;
  width: 100%;
  background-color: white;
  z-index: 10;
  top: 6em;
  width: 15%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 45%;
  overflow: scroll;
  border-radius: 10px;
}

.dropdown-options div {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
  width: 100%;
  font-weight: 300;
}
