.p-signin {
  height: 100%;
  background-color: $pink;
  &__logo {
    width: 100%;
    height: 100px;
  }
  &__login-text {
    font-size: 16px;
    padding-bottom: 10px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    > svg {
      width: 100%;
      height: 200px;
    }
  }

  &__user-selector,
  &__hub-selector {
    padding-bottom: 20px;
    width: 100%;
  }
  &__pin-input {
    align-self: flex-start;
    margin-bottom: 20px;
  }

  &__version {
    font-size: 14px;
    color: darkslategrey;
  }

  &__numpad {
  }
}
