.returned-box-error-indicator {
  position: absolute;
  top: 1em;
  right: 2em;
  width: 35px;
  height: 35px;
  border: 3px solid red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__text {
    font-size: 2em;
    font-weight: bold;
    color: red;
  }
}

.returned-box-error-container {
  text-align: center;
  ul {
    text-align: initial;
  }
  &__text {
    margin-bottom: 1em;
    span {
      font-weight: 700;
      font-size: 1.2em;
      color: #df4040;
    }
  }
  &__error {
    &__text {
    }
  }
}

.last-scanned-boxes-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 1.5em;
  }
  span {
    margin-bottom: 0.2em;
  }
  margin-bottom: 2em;
}
