.inspect-mob-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-top: 1em;
  flex-direction: column;
  overflow: auto;
  &__pr {
    font-size: 0.8em;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  a {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    border: 1px solid #000;
    padding: 0.5em 1em;
  }
}
