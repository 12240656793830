.ship-box-modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    max-width: 10%;
    margin-left: 0.5em;
  }
}

.ship-box-missing-products-container {
  background-color: #ebebeb;
  text-align: center;
  padding: 1em;
  border-radius: 8px;
  ul {
    text-align: initial;
  }
  &__text {
    margin-bottom: 1em;
    span {
      font-weight: 700;
      font-size: 1.2em;
      color: #df4040;
    }
  }
  &__error {
    &__text {
    }
  }
}
