.miniboard-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f3f3f3;
  &__banner {
    background-color: #5c81c9;
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 50% 50%;
    min-height: 50px;
    margin-bottom: 1em;
    font-weight: 700;
  }
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    font-size: 0.7em;
    span {
      font-weight: 700;
    }

    &__infos-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-weight: 700;
      &__state {
        margin-right: 4.5em;
      }
    }
  }
  &__row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    background-color: #fff;
    padding: 0.5em;
    margin: 0.5em 0em;
    img {
      width: 25px;
      padding-left: 1em;
      border-left: 1px solid #c3c3c3;
    }
    &__infos-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      overflow: auto;
      &__name {
        font-weight: 700;
        width: 50%;
        font-size: 0.8em;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &__state {
        width: 20%;
        text-align: left;
        display: flex;
        align-items: center;
        &__boxes {
          display: flex;
          font-size: 0.8em;
        }
        &__status {
          display: flex;
          align-items: center;
          width: 35%;
        }
        &__ongoing {
          color: #5c81c9;
        }
        &__sent {
          color: green;
        }
      }
      .ongoing {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #5c81c9;
        border: 4px solid hsl(0, 100%, 90%);
        box-sizing: border-box;
        margin-right: 0.3em;
      }
      .sent {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: green;
        border: 4px solid hsl(120, 100%, 90%);
        box-sizing: border-box;
        margin-right: 0.3em;
      }
      .todo {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #434343;
        border: 4px solid hsl(0, 0%, 80%);
        box-sizing: border-box;
        margin-right: 0.3em;
      }
    }
  }
}

.miniboard-wrapper__progress-bar-wrapper {
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    span {
      font-weight: 700;
    }
  }
  &__bar {
    width: 100%;
    height: 10px;
    background-color: #ccc;
    border-radius: 2px;
    position: relative;
    overflow: hidden;
    margin-top: 0.3em;
    margin-bottom: 1.5em;
    border: 5px solid white;
    &__fill {
      height: 100%;
      background-color: #5c81c9; /* Blue color for the completed portion */
      transition: width 0.5s ease-in-out;
    }
  }
}
