.v-product-to-scan {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  &__image {
    width: 100%;
    // height: 70px;
  }
  &__name {
    font-size: 18px;
    padding-bottom: 20px;
    color: $error-main;
    font-weight: 600;
  }
  &__scan {
    padding-bottom: 20px;
  }

  &__footer {
    text-align: center;
  }
  &__location {
    font-size: 20px;
    font-weight: 600;
    padding: 4px 10px;
    border-radius: 4px;

    &--to-scan {
      background-color: $to-scan;
    }
    &--scanned {
      background-color: $scanned;
    }
  }
}
