.c-select-option {
  &__item {
    border: 1px solid $grey;
    border-radius: 4px;
    padding: 4px 6px;
    text-align: center;
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    &--disabled {
      opacity: 1;
    }
    &:not(:last-child) {
    }
  }
}

.c-select-mode {
  &__content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    .c-select-option__item {
      padding: 20px !important;
    }
  }
}

.c-select-round {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    padding: 0 10px;
    &--one-col {
      grid-template-columns: 1fr;
    }
  }
}

.line-indicator {
  background-color: #00466d;
  color: white;
  width: 20px;
  border-radius: 4px;
}
