.v-trip-modal {
  &__header {
    text-align: center;
  }
  &__name {
    font-weight: 700;
  }
  &__detail {
    padding: 15px 0;
    div {
      padding: 5px 0;
    }
  }
}
