.p-activate-trip {
  max-width: 300px;
  margin: 20px auto;
  &__container {
    margin-top: 10px;
  }
  &__subtitle {
    font-size: 16px;
    text-align: center;
  }
  &__title {
    text-align: center;
  }
  &__trips {
    margin-top: 20px;
  }
  &__trip {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    align-items: center;
  }
  &__trip-info {
    color: $scanned;
    svg {
      width: 18px;
    }
  }
  &__trip-name {
    font-weight: 700;
    max-width: 70%;
    word-wrap: break-word;
  }

  &__btn {
    border: 1px solid grey;
    border-radius: 4px;
    color: white;
    padding: 7px 22px;

    &--activate {
      border-color: $primary-main;
      background-color: $primary-main;
    }
    &--deactivate {
      border-color: $error-main;
      background-color: $error-main;
    }
  }
  &__status {
    font-size: 15px;
  }

  .created {
  }
  .finished {
    color: $primary-main;
  }
  .started {
    color: $secondary-main;
  }
  &__not-found {
    text-align: center;
  }
}
