.p-admin {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 50px auto;
  &__feature {
    margin-bottom: 10px;
    text-decoration: underline;
  }
}
