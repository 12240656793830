// Colors
$dark: #000000;
$white: #ffffff;
$grey: #757575;
$pink: #f8e6e5;
$text-primary: rgba(0, 0, 0, 0.87);
$text-secondary: rgba(0, 0, 0, 0.6);
$text-disabled: rgba(0, 0, 0, 0.38);

$primary-main: #339760;
$primary-dark: rgba(46, 98, 79, 1);
$secondary-main: rgba(252, 112, 51, 1);
$error-main: #f44336;
$disabled: rgba(0, 0, 0, 0.26);
$bg-disabled: rgba(0, 0, 0, 0.12);
$to-scan: rgba(64, 192, 87, 0.5);
$scanned: rgba(64, 139, 192, 0.5);

$delay: 0.2s;

$header-height: 25px;
$header-padding: 10px;

$br: 4px;
