.missing-product-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  // margin-top: 3em;
  background-color: #f3f3f3;
  &__banner {
    background-color: #ff6161;
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 50% 50%;
    min-height: 50px;
    margin-bottom: 1em;
    font-weight: 700;
  }
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    font-size: 0.7em;
    &__infos-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      &__state {
        margin-right: 8.5em;
      }
    }
  }
  &__row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    background-color: #fff;
    padding: 0.5em;
    margin: 0.5em 0em;
    img {
      width: 25px;
      padding-left: 1em;
      border-left: 1px solid #c3c3c3;
    }
    &__infos-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      overflow: auto;
      &__name {
        font-weight: 700;
        width: 65%;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &__state {
        width: 75%;
        text-align: left;
        display: flex;
        align-items: center;
        &__missing {
          color: red;
        }
        &__available {
          color: green;
        }
        .missing {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: red;
          border: 4px solid hsl(0, 100%, 90%);
          box-sizing: border-box;
          margin-right: 0.3em;
        }
        .available {
          width: 15px; /* Diameter of the circle (radius * 2) */
          height: 15px; /* Diameter of the circle (radius * 2) */
          border-radius: 50%; /* Makes the shape a circle */
          background-color: green; /* Color of the circle's center */
          border: 4px solid hsl(120, 100%, 90%); /* 4px wide border in a much lighter shade of green */
          box-sizing: border-box;
          margin-right: 0.3em;
        }
      }
    }
  }
}

.missing-product-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-align: center;

  &__buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 2em;

    .available {
      background-color: #f04c4c;
      opacity: 1;
    }
    .missing {
      background-color: #4caf50;
      opacity: 1;
    }

    div {
      padding: 0.5em 1em;
      background-color: black;
      opacity: 50%;
      color: white;
      font-weight: 900;
      font-size: 1.3em;
      border-radius: 6px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__confirm {
    }
  }
}
