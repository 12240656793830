$dim: 15px;

.c-pin-container {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  column-gap: 30px;

  label {
    font-size: 16px;
  }
}
.c-pin-grid {
  display: flex;
  align-items: center;

  &__input {
    height: $dim;
    width: $dim;
    border-radius: 50%;
    text-align: center;
    color: transparent;
    border: none;

    background-color: $white;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &:valid {
      background-color: $secondary-main;
      transition: background-color $delay ease-in-out;
    }
  }
}

.c-pin-clear-btn {
  border-radius: 50%;
  text-align: center;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-weight: 600;
}
