.batch-list-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  margin: 0 10px;
  .batch-item {
    margin-bottom: 1em;
  }
  .started {
    color: green;
  }
}
