.infos-container {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.info-slug {
  width: 90vw;
  margin-top: 1.5em;
  border-bottom: 1px solid #c2c2c2;
  padding-bottom: 1em;
  h2 {
    margin-top: 0;
  }
}

.first {
  margin-top: 3em;
}

h2 {
  margin-bottom: 0;
  font-size: 1em;
}

.print-button {
  width: 100%;
  height: 60px;
  background-color: black;
  color: white;
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1;
  margin-top: auto;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  svg {
    position: absolute;
    left: 0.5em;
  }
}

.currently-printing-container {
  width: 100%;
  height: 60px;
  color: white;
  font-size: 1em;
  margin-top: auto;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  color: black;
}

.progress-bar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 30px;
    height: 30px;
    padding-right: 1em;
  }
}

.progress-bar {
  width: 100%;
  background-color: #d9d9d9;
  height: 5px;
}

.progress-bar-layer {
  width: 0%;
  height: 100%;
  background-color: black;
  transition: all 2s ease-in;
}

.wider {
  width: 100%;
}

.printing-done-container {
  display: flex;
  margin-top: 1em;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  span {
    font-weight: bold;
  }
}

.label-error-container {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.print-header {
  display: flex;
  padding: 1em;
  padding-left: 3em;
  border-bottom: 1px solid #c2c2c2;
  width: 100%;

  svg {
    width: 2em;
    height: 2em;
  }
}

.print-scan-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.hub-settings-button {
  &__item {
    border: 1px solid $grey;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    width: 75%;
    padding: 20px !important;
  }
}

.date-text {
  padding-right: 0.5em;
  position: relative;
}

.date-text:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60%;
  width: 100%;
  border-right: 1px solid #757575;
  top: 50%;
  transform: translateY(-50%);
}

.time-slot-text {
  padding-left: 0.5em;
}
