.warehouse-flows-content-wrapper {
  margin-top: 4em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.info-container {
  text-align: center;
  color: white;
  padding: 0.5em;
  min-width: 60%;
  min-height: 3.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.soon-depleted {
  border: 5px solid #ff1111;
}

.low-stock {
  background-color: #ff1111;
  padding: 0em 1.5em;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.2em;
  font-weight: 700;
}

.bold {
  font-weight: 700;
}

.pallet-bg {
  background-color: #6faec1;
}

.dosing-bg {
  background-color: #94559e;
}

.mob-bg {
  background-color: #b8bd79;
}

.prod-bg {
  background-color: #955215;
}

.stock-bg {
  background-color: #8c8c8c;
}

.give-bg {
  background-color: #2c2c2c;
  span {
    color: white;
  }
}

.validate-button {
  margin-top: 27em;
  font-weight: 700;
  height: 3em;
  background-color: #64ac5d;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  color: white;
  position: absolute;
  &__disabled {
    background-color: #949494;
    opacity: 0.5;
  }
}

.pcb-button {
  margin-top: 1em;
  position: static;
}

.step-title {
  position: absolute;
  margin: 0 auto;
  font-size: 1.3em;
  top: 0.5em;
}

.barcode-scanner {
  margin-top: 4em;
  width: 55%;
}

.in-step {
  margin-top: 0em;
  width: 30%;
}

.down-arrow {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  width: 10%;
  &__small {
    width: 5%;
  }
}

.movement-instruction {
  width: 100%;
  position: absolute;
  max-width: 343px;
  bottom: 7em;
  background-color: #9fdfab;
  left: 0;
  text-align: center;
  padding: 0.5em;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.movement-instruction-bold {
  font-weight: 700;
}

.flow-confirmation-modal {
  width: 100%;
  max-width: 100%;
  height: 7em;
  overflow-wrap: break-word; /* This property is standardized and more recommended */
  word-wrap: break-word; /* Older but widely supported, often used as a fallback */
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-all;
  text-align: center; /* Ensures text is centered if it wraps to multiple lines */
}

.pcb-selector-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pcb-modal-title {
  text-align: center;
  font-size: 1em;
  margin-top: 0em;
}

.pcbs-wrapper {
  border: 1px solid #eeeeee;
  border-radius: 6px;
}

.pcb-element {
  padding: 1em 0.5em;
  border-bottom: 1px solid #eeeeee;
}

.pcb-element:last-child {
  border-bottom: none;
}

.is-selected {
  background-color: #64ac5d;
  color: white;
  font-weight: 700;
}

.dropdown-wrapper {
  display: flex;
  font-weight: 700;
  align-items: center;
  width: 90%;
}

.pcb-qty-selector-wrapper {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 1em;
}

.modal-text-bold {
  font-weight: 700;
}

.flow-confirmation-modal-text-wrapper {
  display: flex;
  flex-direction: column;
}
