.back-button-wrapper {
  position: absolute;
  top: 1em;
  left: 1em;
}

.title-container {
  width: 90%;
}

.step-title {
  position: absolute;
  margin: 0 auto;
  font-size: 1.3em;
  top: 0.5em;
}

.warehouse-flows-wrapper {
  margin-top: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.warehouse-flows-header {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  width: 80%;
  margin: 0 auto 5em auto;
}

.bullet {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: black;
  margin-right: 7px;
}

.flows-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  justify-content: center;
  margin: 0 auto;
  .flows-item {
    margin-bottom: 1em;
  }
}

.flows-item {
  align-self: flex-start;
  text-decoration: underline;
  list-style-type: disc;
  display: flex;
  align-items: center;
}
