.p-supply-product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - (92px));
  &__product-to-scan {
    margin-top: 5px;
    margin-bottom: 20px;
  }
  &__rack-full {
    text-align: center;
    font-weight: 500;
    max-width: 90%;
    margin: auto;
  }
  &__navigate-btn {
    margin-top: 30px;
  }

  &__rack-details {
    // position: fixed;
  }

  &__trip-completed {
    text-align: center;
    margin-top: 50px;
  }
  &__change-trip {
    text-decoration: underline;
    color: $secondary-main;
    font-weight: 600;
    text-align: center;
  }
}
