.c-id-input {
  display: grid;
  grid-template-columns: 1fr 5fr;
  column-gap: 10px;
  align-items: center;
  &__label {
    font-size: 16px;
  }

  &__input {
    padding: 8px 8px;
    border-radius: 4px;
    border: none;
  }
}
