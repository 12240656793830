.v-rack-details {
  background-color: lightgrey;
  padding: 10px;
  font-size: 16px;

  &__rack {
    padding-bottom: 4px;
  }
  &__round,
  &__rack-name {
    color: $error-main;
    font-weight: 600;
    padding-bottom: 4px;
  }
  &__total-products {
    color: $white;
    border-radius: $br;
    font-weight: 600;

    background-color: $primary-main;
    padding: 3px 8px;
  }
  &__missing-products {
    text-decoration: underline;
    font-weight: 500;
  }
}
