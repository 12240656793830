.v-fill-scan {
  max-width: 320px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  &__place {
    text-align: center;
  }
  &__place-number {
    color: $secondary-main;
    font-weight: 600;
    font-size: 22px;
  }
  &__qty-to-scan {
    text-align: center;
    span {
      color: $error-main;
      font-weight: 600;
      font-size: 18px;
    }
  }

  &__product {
    display: grid;
    grid-template-columns: 1fr 1fr;
    &-img {
      width: 100%;
      height: 150px;
    }
  }

  &__scanned-container {
    text-align: center;
  }
  &__scanned-product {
    color: $primary-main;
    font-weight: 600;
  }
  &__to-scan {
    color: $error-main;
    font-weight: 600;
  }

  &__note {
    font-size: 16px;
  }
}
