.v-previous-product {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
  &__header {
    font-size: 14px;
  }
  &__title,
  &__location {
    font-size: 16px;
    color: $secondary-main;
    font-weight: 600;
    text-decoration: underline;
  }

  &__cancel-btn {
    margin-top: 10px;
    button {
      background-color: $secondary-main;
      line-height: 120%;
    }
  }
}
