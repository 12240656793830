.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  h3 {
    width: 90%;
  }
}

.faq-item {
  width: 90vw;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c2c2c2;
  p {
    width: 80%;
  }
}

.text-wrapper {
  width: 90%;
}
