.header {
  // position: fixed;
  right: 0;
  left: 0;

  background: $pink;
  padding: 30px 10px 10px;
  // height: $header-height;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 12%);
  &__general-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__user-name {
    font-weight: 500;
    font-size: 15px;
  }
  &__date {
    font-size: 13px;

    text-transform: capitalize;
  }
  &__warning {
    color: $error-main;
    font-weight: 500;
    text-align: center;
  }
  &__admin-icon {
    color: $secondary-main;
  }
  &__selected-mode {
    font-size: 14px;
  }
  &__change-mode {
    color: $secondary-main;
    text-decoration: underline;
    font-weight: 500;
  }

  &__hub {
    color: $primary-main;
    font-weight: 700;
    letter-spacing: 0.4px;
  }
}


