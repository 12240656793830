.u-page-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 1em; // height: calc(100% - ($header-height + 2 * $header-padding));
}

.u-layout {
  padding: 20px 22px;
}

.u-scan {
  background: $to-scan;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.u-wrong-scan {
  position: absolute;
  left: 5%;
  right: 5%;
  bottom: 4%;
  top: 13%;
  overflow: hidden;
  background-color: $error-main;
  color: $white;
  font-size: 26px;
  font-weight: 500;
  border-radius: 10px;

  height: unset;
}

// React Modal library
.ReactModal__Content {
  inset: 50% 10% auto 50% !important;
}
