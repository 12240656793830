.picking-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__header {
    margin-bottom: 0;
  }
  &__instruction {
    text-align: center;
    font-size: 1.5em;
  }
  &__button {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    padding: 0.3em 1em;
    border-radius: 8px;
    font-size: 1.5em;
    font-weight: 900;
    color: white;
    background-color: #9fdfab;
  }
}

.picking-content-wrapper {
  margin-top: 3em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.picking-footer {
  position: absolute;
  bottom: 0;
  background-color: #e4e4e4;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  &__pr-name {
    color: #ec5500;
    font-weight: 700;
  }
  &__packages-count {
    margin-left: 1em;
    background-color: #8aad75;
    padding: 0 0.5em;
    border-radius: 8px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__infos {
    display: flex;
  }
}

.picking-products-tracker {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background-color: #eeeeee;
  width: 100%;
  height: 20%;
  padding: 0.5em 0;
  margin-top: 1em;
  &__missing-products {
  }
  &__counter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3em;
    background-color: #6b90bc;
    color: white;
    font-weight: 600;
    border-radius: 10px;
    padding: 0.1em 0.4em;
    margin-right: 0.5em;
    &__wrapper {
      display: flex;
      flex-direction: column;
    }
  }
  span {
    font-weight: 700;
    font-size: 1.2em;
  }
}

.picking-product-container {
  margin-top: 2em;
  border: 1px solid black;
  width: 85%;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  &__header {
    font-weight: 600;
    background-color: #f8e6e5;
    width: 100%;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &__image {
    width: 40%;
    max-width: 140px;
  }
  &__name {
    font-weight: 600;
    text-align: center;
  }
  &__barcode-info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    img {
      margin-right: 0.5em;
      width: 10%;
    }
  }
}
.picking-scan-product {
  bottom: 11em;
}

.picking-next-product-info {
  margin-top: 6em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  .next-product-source {
    margin-top: 0.5em;
    padding: 0.5em;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
    background-color: #e8e8e8;
    width: 80%;
  }
}

.cor-done-container {
  margin-top: 7em;
  &__infos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 700;
    font-size: 1.5em;
    color: #ec5500;
  }
  &__instructions {
    margin-top: 2em;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.product-banner-picking {
  position: absolute;
  top: 5px;
  right: 5px;
  max-width: 80%;
}
