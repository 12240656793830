.v-numpad {
  &__inline {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 50px;
    margin-bottom: 20px;
  }
  &__num {
    border-radius: 50%;
    background-color: lightgray;
    padding: 4px 10px;
    text-align: center;
    font-size: 16px;
  }
}
