.v-fillv2-scan {
  max-width: 320px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__placev2 {
    text-align: center;
    margin-bottom: 7em;
    &__header {
      font-size: 1.8em;
    }
  }
  &__place-number {
    color: $secondary-main;
    font-weight: 700;
    font-size: 1.8em;
  }
  &__qty-to-scan {
    text-align: center;
    span {
      color: $error-main;
      font-weight: 600;
      font-size: 18px;
    }
  }

  &__product {
    display: grid;
    grid-template-columns: 1fr 1fr;
    &-img {
      width: 100%;
      height: 150px;
    }
  }

  &__scanned-container {
    text-align: center;
  }
  &__scanned-product {
    color: $primary-main;
    font-weight: 600;
  }
  &__to-scan {
    color: black;
    font-weight: 600;
  }

  &__scanned {
    color: #038000;
    font-weight: 600;
  }

  &__note {
    font-size: 16px;
  }
  &__total-products-info {
    width: 100%;
    top: 7em;
    position: absolute;
    background-color: #eeeeee;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    &__infos-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      &__text {
        font-weight: 700;
      }
      &__counter {
        background-color: #6b90bc;
        padding: 0.5em 1em;
        border-radius: 8px;
        color: white;
        margin-right: 0.5em;
      }
    }
  }
}

.fill-scan-instructions-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  &__cor-id {
    margin-left: 0.3em;
    color: #ec5500;
    font-weight: 700;
  }
}
