.button {
  display: block;
  text-align: center;
  padding: 5px 4px;
  border-radius: $br;
  border: none;
  transition: all $delay ease;
  width: 100%;
  color: $white;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  &:first-letter {
    text-transform: capitalize;
  }

  &:disabled {
    color: $disabled;
  }

  &--primary-error {
    color: $white;
    border: none !important;
    background: $error-main;
  }
  &--primary-main {
    color: $white;
    border: none !important;
    background: $primary-main;
  }

  &--secondary-error {
    background: $white;
    border: 1px solid $error-main;
    color: $error-main;
  }
  &--secondary-main {
    background: $white;
    border: 1px solid $primary-main;
    color: $primary-main;
  }

  &--primary-disabled {
    background: $bg-disabled !important;
  }
  &--secondary-disabled {
    border: 1px solid $disabled !important;
  }
}

.button-text {
  border: none;
  background: $white;
  color: $dark;
  text-decoration: underline;
  &:first-letter {
    text-transform: capitalize;
  }
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
}
