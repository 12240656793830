.p-reset-rack {
  max-width: 300px;
  margin: 20px auto;
  &__container {
    margin-top: 10px;
  }
  &__title {
    text-align: center;
  }
  &__not-found {
    text-align: center;
  }
  &__racks {
    margin-top: 20px;
  }
  &__rack {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    align-items: center;
  }
  &__rack-name {
    font-weight: 700;
  }
  &__btn {
    border: 1px solid $secondary-main;
    background-color: $secondary-main;
    border-radius: 4px;
    color: white;
    padding: 7px 22px;
  }
  &__not-found {
    color: $error-main;
  }
}
