.v-scan-product {
  padding: 10px 0;
  width: 100%;
  text-align: center;
  font-weight: 500;
  &--to-scan {
    background: $to-scan;
  }
  &--scanned {
    background: $scanned;
  }
  &__svg {
    max-width: 200px;
    margin: 10px auto 0;
    height: 30px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
